import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";
import ImageGallery from "react-image-gallery"
const images = [
    {
        original: require('../images/lyft-data-challenge/lyft-data-challenge-1.jpg'),
        thumbnail: require('../images/lyft-data-challenge/lyft-data-challenge-1.jpg')
    },
    {
        original: require('../images/lyft-data-challenge/lyft-data-challenge-2.jpg'),
        thumbnail: require('../images/lyft-data-challenge/lyft-data-challenge-2.jpg')
    },
    {
        original: require('../images/lyft-data-challenge/lyft-data-challenge-3.jpg'),
        thumbnail: require('../images/lyft-data-challenge/lyft-data-challenge-3.jpg')
    },
    {
        original: require('../images/lyft-data-challenge/lyft-data-challenge-4.jpg'),
        thumbnail: require('../images/lyft-data-challenge/lyft-data-challenge-4.jpg')
    },
    {
        original: require('../images/lyft-data-challenge/lyft-data-challenge-5.jpg'),
        thumbnail: require('../images/lyft-data-challenge/lyft-data-challenge-5.jpg')
    },
]

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>Lyft Data Challenge</h1>
            <h4>September - October 2019</h4>
            <p>
                For this project, I worked with another undergraduate student to provide business recommendations and analyze data provided to us by Lyft. Our goal was to be able to determine the projected lifetime of a driver, determine the best drivers, and determine a given driver's value to Lyft. Through looking at the data we were able to gather some ideas, and then through our analysis using Machine Learning techniques, we generated a model for determining how valuable a given driver was. In the end, we gave our recommendations that Lyft should incentivize early engagement, emphasize subconscious improvements, and refocus on returning drivers. You can view  the full report below:
            </p>
            <ImageGallery showFullscreenButton={false} showPlayButton={false} items={images}></ImageGallery>
        </ProjectPage>
    </div>)
}

export default IndexPage
